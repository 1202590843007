import { memo, useContext, useMemo } from 'react'

import { Avatar, Box, Typography } from '@mui/material'
import gql from 'graphql-tag'
import { useConfirm } from 'material-ui-confirm'
import toast from 'react-hot-toast'

import { useMutation, useQuery } from '@redwoodjs/web'

import { useAvatarCache } from 'src/components/AvatarCacheProvider/AvatarCacheProvider'
import { DayContext } from 'src/lib/dayContext'
import { NativeObjectTypes } from 'src/lib/objects'

import { GET_PERSON_BASIC } from '../People/queries'
import Row from '../Row/Row'

const REQUEST_PERSON_ENRICHMENT = gql`
  mutation RequestPersonEnrichment($email: String!) {
    requestPersonEnrichment(email: $email)
  }
`

function emailToBackgroundColor(str) {
  if (str && str.length === 0) return '#f0f0f0'
  const firstChar = str ? str.charAt(3) : 'M'
  const charCode = firstChar.charCodeAt(0)
  const colors = [
    '#5AB298',
    '#F9A826',
    '#F26D85',
    '#F2C94C',
    '#A3A0FB',
    '#F26D85',
    '#5AB298',
    '#F9A826',
    '#F2C94C',
    '#A3A0FB',
    '#F26D85',
    '#5AB298',
    '#F9A826',
    '#F2C94C',
    '#A3A0FB',
    '#F26D85',
    '#5AB298',
    '#F9A826',
    '#F2C94C',
    '#A3A0FB',
    '#F26D85',
    '#5AB298',
    '#F9A826',
    '#F2C94C',
    '#A3A0FB',
    '#F26D85',
    '#5AB298',
    '#F9A826',
    '#F2C94C',
    '#A3A0FB',
    '#F26D85',
    '#5AB298',
    '#F9A826',
    '#F2C94C',
    '#A3A0FB',
    '#F26D85',
    '#5AB298',
    '#F9A826',
    '#F2C94C',
    '#A3A0FB',
    '#F26D85',
    '#5AB298',
    '#F9A826',
    '#F2C94C',
    '#A3A0FB',
    '#F26D85',
    '#5AB298',
    '#F9A826',
    '#F2C94C',
    '#A3A0FB',
    '#F26D85',
    '#5AB298',
    '#F9A826',
    '#F2C94C',
    '#A3A0FB',
    '#F26D85',
  ]
  return colors[charCode % colors.length]
}

type ContactAvatarProps = {
  email?: string
  size?: number
  border?: number
  borderRadius?: number
  style?: React.CSSProperties
  showVerification?: boolean
  showSidebar?: boolean
  useFallback?: boolean
  passedPhotoUrl?: string
}

const getCacheKey = (props: ContactAvatarProps): string => {
  const {
    email,
    size,
    border,
    borderRadius,
    showVerification,
    showSidebar,
    useFallback,
    passedPhotoUrl,
  } = props
  return `${email}-${size}-${border}-${borderRadius}-${showVerification}-${showSidebar}-${useFallback}-${passedPhotoUrl}`
}

const BaseContactAvatar = memo(
  ({
    email,
    size,
    border,
    borderRadius = 100,
    style,
    showVerification = false,
    showSidebar = false,
    passedPhotoUrl = null,
  }: ContactAvatarProps) => {
    const confirm = useConfirm()
    const { selectedWorkspace, peopleByEmail, setSidebarObject } =
      useContext(DayContext)

    const shouldQueryPhoto = useMemo(() => {
      return (
        !passedPhotoUrl &&
        email &&
        (!selectedWorkspace || Object.keys(peopleByEmail)?.length > 0) &&
        !peopleByEmail[email]?.photoUrl &&
        email !== 'assistant@day.ai'
      )
    }, [peopleByEmail, email, selectedWorkspace, passedPhotoUrl])

    const { data: personData } = useQuery(GET_PERSON_BASIC, {
      variables: { email },
      skip: !shouldQueryPhoto,
    })

    let photoUrl = passedPhotoUrl || personData?.getPersonPublic?.photoUrl

    const [requestPersonEnrichment] = useMutation(REQUEST_PERSON_ENRICHMENT, {
      variables: { email },
    })

    const handleRequestPersonEnrichment = async () => {
      try {
        await confirm({
          title: 'Enrich Person',
          description:
            'Send Day.ai to research and verify this person. The results will appear automatically when complete.',
        })
        toast.promise(requestPersonEnrichment(), {
          loading: 'Requesting person enrichment...',
          success: 'Request underway!',
          error: 'Error requesting person enrichment',
        })
      } catch (e) {
        console.error(e)
      }
    }

    const fontSize: string = size ? `${Math.floor(0.42 * size)}px` : '48px'

    if (!border) border = 0

    // Hardcode assistant photo
    if (email === 'assistant@day.ai')
      photoUrl = `${process.env.HOST}/logos/Day 1.png`

    const finalBorderRadius = borderRadius.toString() + 'px'

    const initials = useMemo(() => {
      const firstName = personData?.getPerson?.firstName
      if (firstName) {
        const lastName = personData?.getPerson?.lastName
        return `${firstName?.charAt(0) || ''}${lastName?.charAt(0) || ''}`
          .toUpperCase()
          .trim()
      } else {
        return typeof email === 'string'
          ? `${email?.charAt(0) || ''}`.toUpperCase().trim()
          : null
      }
    }, [personData, email])

    const avatarContent = photoUrl ? (
      <Avatar
        src={photoUrl}
        sx={{
          height: `${size}px`,
          width: `${size}px`,
          background: emailToBackgroundColor(email),
          border: (theme) =>
            `${border}px solid ${theme.palette.background.paper} !important`,
          fontSize,
          objectFit: 'cover',
          fontWeight: 600,
          borderRadius: finalBorderRadius,
          overflow: 'hidden',
          textDecoration: 'none',
          '&:not(a)': {
            textDecoration: 'none',
          },
          '& .MuiAvatar-fallback': {},
          ...style,
        }}
      />
    ) : (
      <Avatar
        sx={{
          height: `${size}px`,
          width: `${size}px`,
          borderRadius: finalBorderRadius,
          overflow: 'hidden',
          backgroundColor: (theme) => theme.palette.text.primary,
          border: (theme) =>
            `${border}px solid ${theme.palette.background.paper} !important`,
        }}
        onClick={() => {
          if (showVerification) {
            handleRequestPersonEnrichment()
          }
        }}
      >
        <Row
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Typography
            sx={{
              fontSize: `${fontSize} !important`,
              height: `calc(${Math.floor(size / 2)}px + ${Math.floor(
                size / 20
              )}px) !important`,
              color: (theme) => theme.palette.primary.contrastText,
              fontWeight: '600 !important',
              lineHeight: '100% !important',
              mb: `${-1 * Math.floor(size / 10)}px !important`,
            }}
          >
            {initials}
          </Typography>
        </Row>
      </Avatar>
    )

    return (
      <Box
        className="personAvatarBox"
        sx={{
          cursor: showSidebar || showVerification ? 'pointer' : 'default',
          display: 'inline-flex',
          verticalAlign: 'middle',
        }}
        onClick={(e) => {
          if (showVerification && !showSidebar) {
            e.stopPropagation()
            handleRequestPersonEnrichment()
          } else if (showSidebar) {
            e.stopPropagation()
            setSidebarObject({
              objectId: email,
              objectType: NativeObjectTypes.Contact,
              properties: {},
            })
          }
        }}
      >
        {avatarContent}
      </Box>
    )
  }
)

const CachedContactAvatar = (props: ContactAvatarProps) => {
  const { getCachedAvatar, setCachedAvatar } = useAvatarCache()
  const cacheKey = getCacheKey(props)

  const cachedAvatar = getCachedAvatar(cacheKey)
  if (cachedAvatar) {
    return cachedAvatar
  }

  const newAvatar = <BaseContactAvatar {...props} />
  setCachedAvatar(cacheKey, newAvatar)
  return newAvatar
}

export default CachedContactAvatar
